import * as React from 'react';
import { PageProps } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { PageHeadline } from '../components/page-headline/page-headline';
import { ALL_PRIVACY_POLICY_TOPICS } from '../const/privacy-policy';
import { QuestionsAnswersList } from '../components/questions-answers-list/questions-answers-list';

const questionsAndAnswersTranslations = ALL_PRIVACY_POLICY_TOPICS.map(
  (_, index) => ({
    question: `privacy_policy.data_list.${index}.question`,
    answer: `privacy_policy.data_list.${index}.answer`,
  })
);

const PrivacyPolicyPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'privacy_policy.seo_title' })}
        description={intl.formatMessage({
          id: 'privacy_policy.seo_description',
        })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="privacy_policy.headline" />
        </PageHeadline>
        <QuestionsAnswersList data={questionsAndAnswersTranslations} />
      </div>
    </MainTemplate>
  );
};

export default PrivacyPolicyPage;
